import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  SettingOutlined,
  DollarCircleOutlined,
  BarChartOutlined,
  ReadOutlined,
  ShopOutlined,
  CalendarOutlined,
  CarOutlined,
  DollarOutlined,
  AlertOutlined
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/product/register" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/product",
    sidebar: {
      icon: <DollarOutlined />,
      label: "제품 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "전체 제품 리스트",
        },
        component: loadable(() => import("../pages/product/List")),
      },
      {
        exact: true,
        path: "/active",
        sidebar: {
          label: "판매중 제품 리스트",
        },
        component: loadable(() => import("../pages/product/List")),
      },
      {
        exact: true,
        path: "/stop",
        sidebar: {
          label: "판매 중단 제품 리스트",
        },
        component: loadable(() => import("../pages/product/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/product/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/product/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/order",
    sidebar: {
      icon: <DollarOutlined />,
      label: "주문 내역",
    },
    children: [
      {
        exact: true,
        path: "/payment/wait",
        sidebar: {
          label: "결제 대기",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/payment/complete",
        sidebar: {
          label: "결제 완료",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/delivery/wait",
        sidebar: {
          label: "배송 준비중",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/delivery",
        sidebar: {
          label: "배송 중",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/delivery/complete",
        sidebar: {
          label: "배송 완료",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/exchange",
        sidebar: {
          label: "교환 접수",
        },
        component: loadable(() => import("../pages/order/ReturnList")),
      },
      {
        exact: true,
        path: "/exchange/delivery/ready",
        sidebar: {
          label: "교환 반송 준비 중",
        },
        component: loadable(() => import("../pages/order/ReturnList")),
      },
      {
        exact: true,
        path: "/exchange/delivery",
        sidebar: {
          label: "교환 반송 중",
        },
        component: loadable(() => import("../pages/order/ReturnList")),
      },
      {
        exact: true,
        path: "/exchange/delivery/complete",
        sidebar: {
          label: "교환 반송 완료",
        },
        component: loadable(() => import("../pages/order/ReturnList")),
      },
      {
        exact: true,
        path: "/exchange/return/delivery/ready",
        sidebar: {
          label: "교환 배송 준비 중",
        },
        component: loadable(() => import("../pages/order/ReturnList")),
      },
      {
        exact: true,
        path: "/exchange/return/delivery",
        sidebar: {
          label: "교환 배송 중",
        },
        component: loadable(() => import("../pages/order/ReturnList")),
      },
      {
        exact: true,
        path: "/exchange/complete",
        sidebar: {
          label: "교환 완료",
        },
        component: loadable(() => import("../pages/order/ReturnList")),
      },
      {
        exact: true,
        path: "/refund",
        sidebar: {
          label: "환불 접수",
        },
        component: loadable(() => import("../pages/order/ReturnList")),
      },
      {
        exact: true,
        path: "/refund/delivery/ready",
        sidebar: {
          label: "환불 반송 준비 중",
        },
        component: loadable(() => import("../pages/order/ReturnList")),
      },
      {
        exact: true,
        path: "/refund/delivery",
        sidebar: {
          label: "환불 반송 중",
        },
        component: loadable(() => import("../pages/order/ReturnList")),
      },
      {
        exact: true,
        path: "/refund/delivery/complete",
        sidebar: {
          label: "환불 반송 완료",
        },
        component: loadable(() => import("../pages/order/ReturnList")),
      },
      {
        exact: true,
        path: "/refund/complete",
        sidebar: {
          label: "환불 완료",
        },
        component: loadable(() => import("../pages/order/ReturnList")),
      },
      {
        exact: true,
        path: "/complete",
        sidebar: {
          label: "구매 확정",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/cancel",
        sidebar: {
          label: "결제 취소",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/order/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/return/detail/:id",
        component: loadable(() => import("../pages/order/ReturnDetail")),
      },
    ],
  },
  {
    exact: true,
    path: "/regularOrder",
    sidebar: {
      icon: <CalendarOutlined />,
      label: "정기결제 내역",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "구독중",
        },
        component: loadable(() => import("../pages/regularOrder/List")),
      },
      {
        exact: true,
        path: "/end",
        sidebar: {
          label: "구독 종료",
        },
        component: loadable(() => import("../pages/regularOrder/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/regularOrder/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/delivery",
    sidebar: {
      icon: <CarOutlined />,
      label: "배송비 관리",
    },
    children: [
      {
        exact: true,
        path: "/default",
        sidebar: {
          label: "기본 배송비 설정",
        },
        component: loadable(() => import("../pages/delivery/List")),
      },
      {
        exact: true,
        path: "/countryside",
        sidebar: {
          label: "도서산간 지역 설정",
        },
        component: loadable(() => import("../pages/delivery/CountrysideList")),
      },
    ],
  },
  {
    exact: true,
    path: "/point",
    sidebar: {
      icon: <DollarOutlined />,
      label: "포인트 내역",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "포인트 내역",
        },
        component: loadable(() => import("../pages/point/List")),
      },
    ],
  },
  {
    exact: true,
    path: "/report",
    sidebar: {
      icon: <AlertOutlined />,
      label: "신고 내역",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "신고 내역",
        },
        component: loadable(() => import("../pages/report/List")),
      },
    ],
  },
  {
    exact: true,
    path: "/banner",
    sidebar: {
      icon: <AlertOutlined />,
      label: "배너 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "배너 목록",
        },
        component: loadable(() => import("../pages/banner/Detail")),
      },
    ],
  },
];
