import axios from "axios";

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});
customAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.config.url === '/refresh/token' && error.response.status === 403) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        window.location.href = "/";
        window.alert("로그인이 필요한 페이지입니다.");
        return
      } else if (
          error.response &&
          error.response.status === 401 &&
          !error.config._retry
      ) {
        if (!localStorage.getItem("token")) {
          localStorage.removeItem("token");
          window.location.href = "/";
          window.alert("로그인이 필요한 페이지입니다.");
        } else if (!localStorage.getItem("refresh_token")) {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          window.location.href = "/";
          window.alert("로그인이 필요한 페이지입니다.");
        } else {
          const {
            data: {
              data: { access_token, refresh_token },
              success
            },
          } = await customAxios.post("/refresh/token", {
            refresh_token: localStorage.getItem("refresh_token"),
          });
          if (success) {
            localStorage.setItem("token", access_token);
            localStorage.setItem("refresh_token", refresh_token);
          } else {
            localStorage.removeItem("token");
            localStorage.removeItem("refresh_token");
            window.location.href = "/";
            window.alert("로그인이 필요한 페이지입니다.");
          }
        }
      }
      return Promise.reject(error);
    }
)
const useAxios = () => {
  return customAxios;
};

export default useAxios;
